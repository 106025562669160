.header {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;

  &::before {
    content: "";
    position: absolute;
    width: 2rem;
    left: -2rem;
    height: 100%;
    background-color: #fff;
    top: 0;
  }
}

.titleValue {
  font-size: 1.5rem;
  margin-right: 3rem;
  font-family: var(--title-font);
  font-weight: 500;
  color: black;
}

.title {
  font-size: 1.71rem;
  font-family: var(--title-font);
  font-weight: 500;
  color: #000;
}

.tab {
  flex-basis: 9.75rem !important;
  width: 9.75rem !important;
  flex-shrink: 0;
  justify-content: center;
}

.dateSlider {
  height: 2.813rem;
}

.button {
  height: 2.5rem;
}

.dateSlider {
  margin-right: auto;
}

.switchGroupBy {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 1rem;
}

@media screen and (max-width: 1441px) {
  .tab {
    flex-basis: 8rem !important;
    width: 8rem !important;
  }

  .button {
    font-size: 1rem !important;
  }
}

.templateNarrow {
  padding: 1.88rem 1rem;
  padding-bottom: 0;
}

.headerNarrow {
  //padding: 0 1.76rem 0 3.88rem;
  margin-bottom: 0;
}

.ks6aButton {
  font-size: 1rem;
  padding: 0.7125rem 2.31rem;
}

.remarksButton {
  font-size: 1rem;
  padding: 0.7125rem 2.31rem;
  margin-left: auto;
}

.measureButton {
  min-width: 9.875rem;
}

@media screen and (max-width: 1919px) {
  .tabs {
    & > div {
      width: 9rem;
    }
  }

  .newTicket {
    padding: 0 1rem;
  }
}

.headerTemplate {
  max-width: calc(100vw - 13.95rem);
}

.headerTemplateKs6A {
  margin-left: 3.88rem;
  padding-right: 10px;
}

@media screen and (max-width: 1441px) {
  .tabs {
    & > div {
      width: 10.275rem;
    }
  }
}

.sectionParentRow {
  & div {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    font-style: normal;
    color: #000000;
  }

  &:before {
    content: " ";
    width: 0.375rem;
    height: 2.375rem;
    background-color: var(--color-background-primary);
    border-radius: 0.25rem;
    position: absolute;
  }

  &.gridColumns {
    grid-template-columns: 100%;
  }
}

.tabWithIndicator {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
