.tab {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: var(--color-indicator-frostbite);
  flex-shrink: 0;
}

.tabPlaceholder {
  width: 0.5rem;
  flex-shrink: 0;
}

.section {
  height: 2rem;
  min-width: 2rem !important;
  border-radius: 50%;
  background-color: var(--color-indicator-frostbite);
  flex-shrink: 0;
  flex-basis: 2rem !important;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.875rem;
}
